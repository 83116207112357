import React from "react";
import Layout from "../components/Layout";
import { Link, graphql } from "gatsby";
import map from "../img/map.png"
import "./map.sass"
import { GatsbyImage } from "gatsby-plugin-image";
import { useState } from "react";
import cross from "../img/cross.svg"


const MapPage = ({ data }) => {

  const { edges } = data.allMarkdownRemark;
  const placesRaw = edges.filter(edge => edge.node.frontmatter.templateKey === "place");
  const classes = edges.filter(edge => edge.node.frontmatter.templateKey === "class");
  const [places, setPlaces] = useState(placesRaw.map(place => (
    {
      id: place.node.id,
      name: place.node.frontmatter.name,
      image: place.node.frontmatter.image.childImageSharp.gatsbyImageData,
      isOpen: false
    }
  )));
  const togglePopup = (id) => {

    setPlaces(places.map(place => {
      if (place.id === id) {
        return {
          ...place,
          isOpen: !place.isOpen
        }
      } else {
        return {
          ...place,
          isOpen: false
        }
      }
      return place;
    }))
  }

  const openPopup = (id) => {
    setPlaces(places.map(place => {
      if (place.id === id) {
        return {
          ...place,
          isOpen: true
        }
      } else {
        return {
          ...place,
          isOpen: false
        }
      }
      return place;
    }))
  }


  const closePopups = () => {
    setPlaces(places.map(place => {
      return {
        ...place,
        isOpen: false
      }
    }))
  }


  return (
    <Layout>
      <div className="section-container">
        <section className="section">
          <div className="map-info-container">
            <div className="map-container">
              <h5>RUSKA</h5>
              <div className="map-with-markers">
                <img src={map}></img>
                {places.map((place, index) => (
                  <div key={place.id} className="marker" onClick={() => togglePopup(place.id)}>

                    <p className="marker-index">{index + 1}</p>
                    <div className={`popup-image-map ${place.isOpen ? "active" : ""}`}>
                      <div className="close-popup">
                        <img src={cross} onClick={() => closePopups()}></img>
                      </div>
                      <GatsbyImage image={place.image} alt="place image" />
                      <p>{place.name}</p>
                    </div>
                  </div>
                )
                )}
              </div>
              <p className="map-description">BRAMA WEJŚCIOWA</p>
              <h5>SWIĘTEGO ANTONIEGO</h5>
            </div>
            <div className="map-list">

              {places.map((place, index) => (
                <div key={place.id}>
                  <p style={{ fontWeight: "500" }}>{(index + 1) + ". " + place.name}</p>
                  {
                    [... new Set(classes.filter(classItem => classItem.node.frontmatter.place === place.name).map(classItem => classItem.node.frontmatter.title))].map((className, index) => {
                      return (
                        <Link to={classes.filter(classItem => classItem.node.frontmatter.title === className)[0].node.fields.slug}><p className="underline" key={index} style={{ cursor: "pointer" }}>{className}</p></Link>
                      )

                    }
                    )}
                </div>
              )
              )}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

const PageMarker = ({ index }) => {
  return (
    <div className="page-marker">
      <p>[index]</p>
    </div>
  )
}

export default MapPage;

export const pageQuery = graphql`
  query MapPage {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {in: ["place", "class"]}}}
      sort: {fields: frontmatter___index, order: ASC}
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            name
            place
            templateKey
            index
            image {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
      }
    }
  }
`